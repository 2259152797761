module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Root Insurance Website","short_name":"Root Insurance","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/assets/images/monogram.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5b243589c73c9e4dd34932f3c84ab8b8"},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b3613c76824d4e71a26719eb092351fb@sentry.io/1871380","release":"2024-09-17-070222-f289607ef124d7c37b329f75a185dc5afd9c1cdb","tracesSampleRate":0.05,"browserTracingOptions":{"tracingOrigins":["localhost","joinroot.com",{}],"idleTimeout":2000}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
